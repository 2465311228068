export default class AttendanceDetail {
  recordid: string;
  employee: string;
  affiliation: string;
  shiftid: string;
  shiftpatternid: string;
  datestr: string;
  date: string;
  start: string;
  end: string;
  opeTime: string;
  distance: string;
  inover: string;
  lateleave: string;
  amount: string;
  breakflg: string;
  daycount: string;
  nightcount: string;
  hadmeal: string;
  careShu: string;
  careKbn: string;
  timeKbn: string;
  assistFlag: string;
  otherFlag: string;
  constructor(props: {
    recordid: string;
    employee: string;
    affiliation: string;
    shiftid: string;
    shiftpatternid: string;
    datestr: string;
    date: string;
    start: string;
    end: string;
    opeTime: string;
    distance: string;
    inover: string;
    lateleave: string;
    amount: string;
    breakflg: string;
    daycount: string;
    nightcount: string;
    hadmeal: string;
    careShu: string;
    careKbn: string;
    timeKbn: string;
    assistFlag: string;
    otherFlag: string;
  }) {
    this.recordid = props.recordid;
    this.employee = props.employee;
    this.affiliation = props.affiliation;
    this.shiftid = props.shiftid;
    this.shiftpatternid = props.shiftpatternid;
    this.datestr = props.datestr;
    this.date = props.date;
    this.start = props.start;
    this.end = props.end;
    this.opeTime = props.opeTime;
    this.distance = props.distance;
    this.inover = props.inover;
    this.lateleave = props.lateleave;
    this.amount = props.amount;
    this.breakflg = props.breakflg;
    this.daycount = props.daycount;
    this.nightcount = props.nightcount;
    this.hadmeal = props.hadmeal;
    this.careShu = props.careShu;
    this.careKbn = props.careKbn;
    this.timeKbn = props.timeKbn;
    this.assistFlag = props.assistFlag;
    this.otherFlag = props.otherFlag;
  }
}
