<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <v-col cols="12">
          <v-autocomplete
            v-model="selectDays"
            :items="days"
            outlined
            multiple
            clearable
            :rules="[Rules.Required]"
            label="日付"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" class="dialog-padding">
          <v-autocomplete
            v-model="user"
            :items="users"
            outlined
            :rules="[Rules.Required]"
            label="社員"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
          <v-autocomplete
            v-model="shift"
            :items="shifts"
            outlined
            label="シフト"
            @input="shiftSelect"
            item-text="name"
            item-value="code"
            chips
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <!-- <v-col class="dialog-padding">
          <v-text-field
            v-model.number="distance"
            label="移動距離"
            suffix="km"
            type="number"
            step="0.1"
            prepend-inner-icon="mdi-map-marker-distance"
            outlined
          ></v-text-field>
        </v-col> -->
        <v-col class="dialog-padding">
          <v-text-field
            v-model.number="amount"
            label="単価"
            suffix="円"
            type="number"
            step="1"
            prepend-inner-icon="mdi-wallet"
            outlined
          ></v-text-field>
        </v-col>
        <v-col></v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectWork"
            row
            label="出勤種別:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="通常" :value="SelectsWork.Default"></v-radio>
            <v-radio label="休日" :value="SelectsWork.Break"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectLateLeave"
            row
            label="遅刻早退:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsLateLeave.Default"></v-radio>
            <v-radio label="遅刻" :value="SelectsLateLeave.Late"></v-radio>
            <v-radio label="早退" :value="SelectsLateLeave.Leave"></v-radio>
            <v-radio
              label="遅刻早退"
              :value="SelectsLateLeave.LateLeave"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectDayCount"
            row
            label="出勤日数:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="1" :value="SelectsDayCount.Default"></v-radio>
            <v-radio label="2" :value="SelectsDayCount.Over"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectNightCount"
            row
            label="夜勤日数:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="0" :value="SelectsNightCount.Default"></v-radio>
            <v-radio label="1" :value="SelectsNightCount.Over"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectsOption"
            row
            label="応援・その他:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsOption.NONE"></v-radio>
            <v-radio label="応援" :value="SelectsOption.ASSIST"></v-radio>
            <v-radio label="その他" :value="SelectsOption.OTHER"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="dialog-padding">
          <v-radio-group
            v-model="selectAbsence"
            row
            label="欠勤:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsAbsence.Default"></v-radio>
            <v-radio label="あり" :value="SelectsAbsence.Absence"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <p>出退勤時間はシフトの出勤時間、退勤時間が設定されます。</p>
      </v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import AttendanceFix from "@/models/AttendanceFix";
import { COMMON_SHIFT_PATTERNS_REF as CommonShiftRefCode } from "@/defines";

const Users = [{ name: "", code: "" }];
const Shifts = [];

const SelectsWork = {
  Default: 0,
  Break: 1
};

const SelectsLateLeave = {
  Default: 0,
  Late: 1,
  Leave: 2,
  LateLeave: 3
};

const SelectsDayCount = {
  Default: 1,
  Over: 2
};
const SelectsNightCount = {
  Default: 0,
  Over: 1
};
const SelectsOption = {
  NONE: 0,
  ASSIST: 1,
  OTHER: 2
};
const SelectsAbsence = {
  Default: 0,
  Absence: 1
};
export default {
  name: "AttendanceBulkOfficeAddDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],

  computed: {
    Title() {
      return this.officename + " 勤務実績一括追加";
    }
  },
  data() {
    return {
      officecode: "",
      officename: "",
      selectDays: [],
      days: [],
      users: Users,
      user: {},
      shifts: Shifts,
      shift: {},
      toggle: [],
      edit: true,
      recordShifts: {},
      distance: 0,
      amount: 0,
      startTime: "",
      endTime: "",
      breakTime: "",
      selectWork: SelectsWork.Default,
      SelectsWork,
      selectLateLeave: SelectsLateLeave.Default,
      SelectsLateLeave,
      selectDayCount: SelectsDayCount.Default,
      SelectsDayCount,
      selectNightCount: SelectsNightCount.Default,
      SelectsNightCount,
      selectsOption: SelectsOption.NONE,
      SelectsOption,
      selectAbsence: SelectsAbsence.Default,
      SelectsAbsence,
      forPaid: ""
    };
  },
  methods: {
    shiftSelect() {
      console.log("shiftSelect", this.shift, this.recordShifts);
      const filter = this.recordShifts.filter(
        e => e.code === String(this.shift)
      );
      if (filter.length > 0) {
        const set = filter[0];
        this.startTime = set.option1;
        this.endTime = set.option2;
        this.breakTime = set.option3;
        if (set.refcode === "99999") {
          this.forPaid = set.code;
        } else {
          this.forPaid = "0";
        }
      } else {
        this.startTime = "";
        this.endTime = "";
        this.breakTime = "";
        this.forPaid = "0";
      }
    },
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async complete() {
      console.log("complete");

      if (!this.$refs.form.validate()) return;

      const data = { ...this.data };
      const args = this.args;
      Object.keys(args).forEach(key => {
        if (this.data[key]) args[key] = data[key];
      });

      data.date = new Date(this.date).getTime();
      if (this.$isEmpty(data.recordid)) {
        data.datestr = this.date;
        data.employee = this.user;
      }
      data.shiftpatternid = this.shift ? this.shift : "";
      if (this.shift !== null && !this.$isEmpty(this.shift.code)) {
        data.shiftpatternid = this.shift.code ? this.shift.code : "";
      } else {
        data.shiftid = "";
      }
      if (data.shiftpatternid === "") {
        this.forPaid = "0";
      }

      const payLoad = new AttendanceFix({
        recordId: data.recordid,
        employeeId: data.employee,
        affiliationId: data.affiliation,
        targetShiftId: data.shiftid,
        shiftpatternid: data.shiftpatternid,
        attendanceDate: data.datestr,
        startTime: data.start,
        endTime: data.end,
        movingDistance: data.distance,
        breaktime: data.breaktime,
        work: data.work,
        over: data.over,
        midnight: data.midnight,
        midnightover: "",
        inover: data.inover,
        leaveTime: "",
        lateTime: "",
        lateleave: this.selectLateLeave,
        hadmeal: this.selectMeal,
        workclassificationid:
          this.forPaid.length > 0 ? this.forPaid : this.selectWork,
        amount: data.amount,
        reserveAmount1: "",
        reserveAmount2: "",
        reserveid1: this.selectDayCount,
        nightShift: this.selectNightCount,
        careShu: "0",
        careKbn: "0",
        timeKbn: "0",
        nightGuard: "0",
        emergencyFlag: "0",
        midnightTel: "0",
        assistFlag: this.selectsOption === SelectsOption.ASSIST ? "1" : "0",
        otherFlag: this.selectsOption === SelectsOption.OTHER ? "1" : "0",
        absence: this.selectAbsence,
        biko: ""
      });

      //承認済みのチェックを掛ける
      if ((await this.checkapproval(payLoad)) == false) {
        this.$error(
          "既に上長承認が行われているか、\n 給与確定済のため、更新できません。"
        );
        this.$unloading();
        return;
      }

      this.$loading();

      /*try {
        console.log("post parameters", payloads);
        await this.$post(this.Paths.attendanceBulk, payloads);
        await this.$info("登録しました。", "勤務実績");
        console.log("complete end");
        this.$close(true);
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }*/
      this.$loading();
      try {
        let result;
        if (this.$isEmpty(data.recordid)) {
          result = await this.$post(this.Paths.attendanceFix, payLoad);
        } else {
          result = await this.$put(this.Paths.attendanceFix, payLoad);
        }

        if (result) {
          await this.$info("更新しました。", "勤務実績");
          console.log("complete end");
          this.$close(true);
        }
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    },
    async getUser() {
      console.log("user");
      try {
        const result = await this.$get(this.Paths.shain1);
        if (result) {
          this.users.length = 0;
          this.users = result;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async getShiftPattern() {
      console.log("shift-pattern");

      try {
        const result = await this.$get(this.Paths.shiftpatternPulldown);
        if (result) {
          this.shifts.length = 0;
          this.recordShifts.length = 0;
          const filtershifts = result.filter(
            e =>
              e.refcode === this.officecode || e.refcode === CommonShiftRefCode
          );
          this.shifts = filtershifts;
          this.recordShifts = filtershifts;
        }
      } catch (e) {
        console.log(e);
        this.$error(e.message);
      }
    },
    async checkapproval(payLoads) {
      for (let i = 0; i < payLoads.length; i++) {
        const payLoad = payLoads[0];

        const param = payLoad;
        console.log("getRecordByUser", param);
        const encode = encodeURI(JSON.stringify(param));
        const result2 = await this.$get(
          this.Paths.attendanceapproval,
          "query=" + encode
        );

        console.log(result2.approval);

        if (result2.approval === "1" || result2.approval === "2") {
          return false;
        }
      }
      return false;
    },
    async setDays(fromdate, todate) {
      const targetFrom = fromdate;
      const dayofweek = ["日", "月", "火", "水", "木", "金", "土"];
      while (targetFrom <= todate) {
        /*const y = targetFrom.getFullYear();
        const m = ("00" + Number(targetFrom.getMonth() - 1)).slice(-2);
        const d = ("00" + targetFrom.getDate()).slice(-2);
        const date = new Date(y, m, d);*/

        const y = [];
        const m = [];
        const d = [];
        y[0] = targetFrom.getFullYear();
        m[0] = ("00" + Number(targetFrom.getMonth() - 1)).slice(-2);
        d[0] = ("00" + targetFrom.getDate()).slice(-2);
        const date = new Date(y[0], m[0], d[0]);

        if (
          date.getFullYear() == y[0] &&
          date.getMonth() == m[0] &&
          date.getDate() == d[0]
        ) {
          const dt =
            y[0] +
            "-" +
            ("00" + Number(Number(m[0]) + 1)).slice(-2) +
            "-" +
            d[0];
          const dtname = dt + " (" + dayofweek[date.getDay()] + ")";
          this.days.push({
            code: dt,
            name: dtname
          });
        } else if (
          date.getFullYear() + 1 == y[0] &&
          date.getMonth() == 11 &&
          m[0] == "-1" &&
          date.getDate() == d[0]
        ) {
          const dt = date.getFullYear() + "-" + "12" + "-" + d[0];
          const dtname = dt + " (" + dayofweek[date.getDay()] + ")";
          this.days.push({
            code: dt,
            name: dtname
          });
        }

        if (
          date.getMonth() == 2 ||
          date.getMonth() == 4 ||
          date.getMonth() == 7 ||
          date.getMonth() == 9
        ) {
          if (date.getDate() == 30) {
            //31日を追加する
            const dt =
              date.getFullYear() +
              "-" +
              ("00" + (date.getMonth() + 1)).slice(-2) +
              "-31";
            const date2 = new Date(y[0], m[0], 31);
            const dtname = dt + " (" + dayofweek[date2.getDay()] + ")";
            this.days.push({
              code: dt,
              name: dtname
            });
          }
        }

        targetFrom.setDate(targetFrom.getDate() + 1);
      }
    }
  },
  async created() {
    console.log("created", this.args);

    this.officecode = this.args.officecode;
    this.officename = this.args.officename;
    const fromdate = this.args.fromdate;
    const todate = this.args.todate;

    await this.setDays(fromdate, todate);
    await this.getUser();
    await this.getShiftPattern();
  }
};
</script>

<style scoped>
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
</style>
