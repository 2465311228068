<template>
  <DialogBase
    visible
    ref="dialog"
    :title="Title"
    icon="mdi-information"
    maxWidth
    complete
    close
    @clickComplete="complete(true)"
    @clickClose="close(false)"
  >
    <v-form v-model="valid" ref="form">
      <!-- {{ edit }} -->
      <v-row>
        <p>選択した勤怠実績を入力した内容で更新します。</p>
      </v-row>
      <v-row v-if="(auth = 1)">
        <p>上長承認がされている場合は、更新させれません。</p>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <TimePicker
            v-model="starttime"
            label="勤務開始"
            prepend-inner-icon="mdi-clock-start"
            outlined
            :rules="[Rules.Required]"
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="endtime"
            label="勤務終了"
            prepend-inner-icon="mdi-clock-end"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="breaktime"
            label="休憩"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="work"
            label="実働"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <TimePicker
            v-model="over"
            label="時間外残業"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="inover"
            label="時間内残業"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="midnightover"
            label="深夜残業"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="midnight"
            label="深夜時間"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <TimePicker
            v-model="lateTime"
            label="遅刻時間"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <TimePicker
            v-model="leaveTime"
            label="早退時間"
            prepend-inner-icon="mdi-clock-start"
            outlined
          />
        </v-col>
        <v-col class="dialog-padding">
          <v-text-field
            v-model.number="amount"
            label="単価"
            suffix="円"
            type="number"
            step="1"
            prepend-inner-icon="mdi-wallet"
            outlined
          ></v-text-field>
        </v-col>
        <v-col class="dialog-padding">
          <v-text-field
            v-model.number="reserveAmount1"
            label="応援時給単価"
            suffix="円"
            type="number"
            step="1"
            prepend-inner-icon="mdi-wallet"
            :disabled="selectsOption !== SelectsOption.ASSIST"
            outlined
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col class="dialog-padding">
          <v-text-field
            v-model="biko"
            label="備考"
            prepend-inner-icon="mdi-wallet"
            outlined
          ></v-text-field>
        </v-col>
        <v-col class="dialog-padding"></v-col>
        <v-col class="dialog-padding"></v-col>
        <v-col class="dialog-padding"></v-col>
      </v-row>
      <v-row class="margin-row">
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectWork"
            row
            label="出勤種別:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="通常" :value="SelectsWork.Default"></v-radio>
            <v-radio label="休日" :value="SelectsWork.Break"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectDayCount"
            row
            label="出勤日数:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="1" :value="SelectsDayCount.Default"></v-radio>
            <v-radio label="2" :value="SelectsDayCount.Over"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectsOption"
            row
            label="応援・その他:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsOption.NONE"></v-radio>
            <v-radio label="応援" :value="SelectsOption.ASSIST"></v-radio>
            <v-radio label="その他" :value="SelectsOption.OTHER"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col cols="4" sm="6" xs="6" class="dialog-padding">
          <v-radio-group
            v-model="selectAbsence"
            row
            label="欠勤:"
            class="dialog-padding"
            prepend-icon="mdi-toggle-switch"
          >
            <v-radio label="なし" :value="SelectsAbsence.Default"></v-radio>
            <v-radio label="あり" :value="SelectsAbsence.Absence"></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
    </v-form>
  </DialogBase>
</template>

<script>
import Common from "@/mixins/common";
import Dialog from "@/mixins/dialog";
import Forms from "@/mixins/forms";
import Api from "@/mixins/api";
import ShowDialogs from "@/mixins/showDialogs";
import AttendanceFix from "@/models/AttendanceFix";

const SelectsWork = {
  Default: 0,
  Break: 1
};

const SelectsLateLeave = {
  Default: 0,
  Late: 1,
  Leave: 2,
  LateLeave: 3
};

const SelectsDayCount = {
  Default: 1,
  Over: 2
};
const SelectsNightCount = {
  Default: 0,
  Over: 1
};
const SelectsOption = {
  NONE: 0,
  ASSIST: 1,
  OTHER: 2
};
const SelectsAbsence = {
  Default: 0,
  Absence: 1
};
export default {
  name: "AttendanceBulkFixDialog",
  mixins: [Common, Dialog, Forms, Api, ShowDialogs],

  computed: {
    Title() {
      return "勤務実績一括更新";
    }
  },
  data() {
    return {
      recordIds: [],
      incode: "",
      username: "",
      starttime: "00:00",
      endtime: "00:00",
      breaktime: "00:00",
      work: "00:00",
      over: "00:00",
      inover: "00:00",
      midnight: "00:00",
      midnightover: "00:00",
      leaveTime: "00:00",
      lateTime: "00:00",
      distance: 0,
      amount: 0,
      reserveAmount1: 0,
      biko: "",
      recordShifts: {},
      selectWork: SelectsWork.Default,
      SelectsWork,
      selectLateLeave: SelectsLateLeave.Default,
      SelectsLateLeave,
      selectDayCount: SelectsDayCount.Default,
      SelectsDayCount,
      selectNightCount: SelectsNightCount.Default,
      SelectsNightCount,
      selectsOption: SelectsOption.NONE,
      SelectsOption,
      selectAbsence: SelectsAbsence.Default,
      SelectsAbsence,
      auth: "0"
    };
  },
  methods: {
    close(result) {
      this.resolve(result);
      this.$close();
    },
    async complete() {
      console.log("complete");

      if (!this.$refs.form.validate()) return;

      const payloads = [];

      this.recordIds.forEach(recordId => {
        const payLoad = new AttendanceFix({
          recordId: recordId,
          employeeId: "",
          affiliationId: "",
          targetShiftId: "",
          shiftpatternid: "",
          attendanceDate: "",
          startTime: this.starttime,
          endTime: this.endtime,
          movingDistance: this.distance,
          breaktime: this.breaktime,
          work: this.work,
          over: this.over,
          midnight: this.midnight,
          midnightover: this.midnightover,
          inover: this.inover,
          leaveTime: this.leaveTime,
          lateTime: this.lateTime,
          lateleave: this.selectLateLeave,
          hadmeal: "", // this.selectMeal,
          workclassificationid: this.selectWork,
          amount: this.amount,
          reserveAmount1: this.reserveAmount1,
          reserveAmount2: "",
          reserveid1: this.selectDayCount,
          nightShift: this.selectNightCount,
          careShu: "0",
          careKbn: "0",
          timeKbn: "0",
          nightGuard: "0",
          emergencyFlag: "0",
          midnightTel: "0",
          assistFlag: this.selectsOption === SelectsOption.ASSIST ? "1" : "0",
          otherFlag: this.selectsOption === SelectsOption.OTHER ? "1" : "0",
          absence: this.selectAbsence,
          biko: this.biko
        });
        payloads.push(payLoad);
      });

      this.$loading();
      try {
        console.log("put parameter", payloads);
        await this.$put(this.Paths.attendanceBulk, payloads);
        await this.$info("更新しました。", "勤務実績");
        console.log("complete end");
        this.$close(true);
      } catch (e) {
        console.log("complete error", e);
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    }
  },
  async created() {
    console.log("created", this.args);
    this.incode = this.args.incode;
    this.username = this.args.username;
    this.recordIds = this.args.recordIds;
    const authority = this.$store.state.authority;
    this.auth = authority;
  }
};
</script>

<style scoped>
.margin-row {
  margin-top: 0px;
}
.dialog-padding {
  padding-top: 0px;
}
</style>
