
import Vue, { PropType } from "vue";
import TimePicker2 from "@/components/TimePicker2.vue";
import { InputRules } from "@/rules";

export type AttendanceFixTimeForm = {
  start?: string;
  end?: string;
  opeTime?: string;
  breaktime?: string;
  morningbreaktime?: string;
  midnightbreaktime?: string;
  work?: string;
  over?: string;
  morning?: string;
  midnight?: string;
  stampTime?: string;
  bindTime?: string;
};

const formList = [
  {
    text: "勤務開始",
    value: "start",
    rules: [InputRules.time, InputRules.required],
    disabled: false
  },
  {
    text: "勤務終了",
    value: "end",
    rules: [InputRules.time, InputRules.required],
    disabled: false
  },
  {
    text: "総休憩",
    value: "breaktime",
    rules: [InputRules.timenullok],
    disabled: false
  },
  {
    text: "深夜休憩",
    value: "midnightbreaktime",
    rules: [InputRules.timenullok],
    disabled: false
  },
  {
    text: "実働",
    value: "work",
    rules: [],
    disabled: true
  },
  {
    text: "時間外残業",
    value: "over",
    rules: [],
    disabled: true
  },
  {
    text: "深夜勤務",
    value: "midnight",
    rules: [],
    disabled: true
  },
  {
    text: "拘束時間",
    value: "bindTime",
    rules: [],
    disabled: true
  }
] as const;

export default Vue.extend({
  name: "AttendanceFixTimeForm",
  components: { TimePicker2 },
  props: {
    value: {
      type: Object as PropType<AttendanceFixTimeForm>,
      required: true
    }
  },
  data() {
    return {
      formList,
      minuteInterval: 15
    };
  },
  computed: {
    modelValue: {
      get(): AttendanceFixTimeForm {
        return this.value;
      },
      set(newValue: AttendanceFixTimeForm) {
        this.$emit("input", newValue);
      }
    }
  }
});
